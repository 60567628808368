import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  { path: "/signIn", component: () => import("@/pages/login/index.vue") },
  {
    path: "/",
    component: () => import("@/layouts/TheLayout.vue"),
    redirect: "/signIn",
    // beforeEnter: onCheckLogin,
    children: [
      { path: "/user/student", component: () => import("@/pages/user/index.vue") },
      { path: "/user/team", component: () => import("@/pages/user/team.vue") },
      { path: "/user/center", component: () => import("@/pages/user/center.vue") },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/TheLayout.vue"),
    redirect: "/signIn",
    // beforeEnter: onCheckLogin,
    children: [
      { path: "/permission/admin", component: () => import("@/pages/permission/index.vue") },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/TheLayout.vue"),
    // redirect: "/signIn",
    // beforeEnter: onCheckLogin,
    children: [
      { path: "/education/text-book", component: () => import("@/pages/education/textBook.vue") },
      { path: "/education/text-book/question", component: () => import("@/pages/education/question/index.vue") },
      { path: "/education/curriculum", component: () => import("@/pages/education/curriculum.vue") },
      { path: "/education/statistics", component: () => import("@/pages/education/statistics.vue") },

    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/TheLayout.vue"),
    children: [
      { path: "/mypage/change-password", component: () => import("@/pages/permission/changePassword.vue") },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(), routes,
});

export default router;
